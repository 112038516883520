import { createApp } from 'vue'
import App from './App.vue'
import router from './router' // separate module directory
import store from '@/js/store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import awsconfig from './js/aws-exports';
import Amplify from 'aws-amplify';
import ScriptX from 'vue-scriptx'
import VueApexCharts from "vue3-apexcharts";
import VueHighlightJS from 'vue3-highlightjs'
// import 'highlight.js/styles/darcula.css'
import 'highlight.js/styles/an-old-hope.css'
import WOW from 'wowjs' // Import WOW.js
import mitt from 'mitt'
import { globalCookiesConfig } from "vue3-cookies";


globalCookiesConfig({
    expireTimes: new Date(2999,1,1).toUTCString(),
    path: "/",
    domain: "",
    secure: true,
    sameSite: "None",
});



console.log(awsconfig.Auth.region)

Amplify.configure(awsconfig)

import { applyPolyfills, defineCustomElements } from '@aws-amplify/ui-components/loader'
applyPolyfills().then(() => {
    defineCustomElements(window)
})

const app = createApp(App)

app.use(Amplify)
app.use(ScriptX)
app.use(store)
app.use(router)
app.use(VueAxios, axios)
app.use(VueApexCharts)
app.use(VueHighlightJS)

// app.component('font-awesome-icon', FontAwesomeIcon)

// event emitter
const emitter = mitt()
app.config.globalProperties.emitter = emitter


// Initialize WOW.js
app.mount('#app').$nextTick(() => {

}).then(() =>
    new WOW.WOW({
        live: false // If you want to re-apply the animation any time the DOM changes, set live: true
    }).init()
)
